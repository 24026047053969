import { PureComponent } from "react"

export interface Props {
  condition: boolean
  delay?: number
  children: React.ReactNode
}

interface State {
  mountDelay: boolean
  propsDelay: boolean
}

export class Delayed extends PureComponent<Props, State> {
  private mountTimerId: number | undefined
  private propsTimerId: number | undefined

  static defaultProps: Partial<Props> = {
    delay: 1000
  }

  constructor(props: Props) {
    super(props)
    this.state = { mountDelay: false, propsDelay: false }
  }

  componentDidMount() {
    this.mountTimerId && clearTimeout(this.mountTimerId)
    this.setState({ mountDelay: true })
    this.mountTimerId = setTimeout(() => {
      this.setState({ mountDelay: false })
    }, this.props.delay)
  }

  componentWillUnmount() {
    this.mountTimerId && clearTimeout(this.mountTimerId)
    this.propsTimerId && clearTimeout(this.propsTimerId)
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.condition === prevProps.condition) {
      return
    }
    this.propsTimerId && clearTimeout(this.propsTimerId)
    this.setState({ propsDelay: true })
    this.propsTimerId = setTimeout(() => {
      this.setState({ propsDelay: false })
    }, this.props.delay)
  }

  render() {
    if (this.props.condition && !this.state.mountDelay && !this.state.propsDelay) {
      return this.props.children
    }
    return null
  }
}

import { useEffect } from "react"

import "vanilla-cookieconsent/dist/cookieconsent.css"
import * as CookiesConsent from "vanilla-cookieconsent"
import { useLocation } from "react-router"
import { analyticsCookieCategory, hasAnalyticsConsent } from "@infrastructure/ga/analyticsConsent"

interface CookieConsentProps {
  setCookieConsent: (value: boolean) => void
}

export default function CookieConsent({ setCookieConsent }: CookieConsentProps) {
  const location = useLocation()

  useEffect(() => {
    CookiesConsent.run({
      autoShow: false,
      categories: {
        necessary: {
          enabled: true,
          readOnly: true
        },
        analytics: {
          autoClear: {
            cookies: [
              {
                name: /^_ga/
              },
              {
                name: "_gid"
              }
            ]
          }
        }
      },
      guiOptions: {
        consentModal: {
          layout: "cloud",
          position: "bottom center",
          flipButtons: false,
          equalWeightButtons: true
        }
      },
      onConsent: ({ cookie }) => {
        setCookieConsent(hasAnalyticsConsent(cookie))
      },
      onFirstConsent({ cookie }) {
        setCookieConsent(hasAnalyticsConsent(cookie))
      },
      onChange({ cookie, changedCategories }) {
        if (changedCategories.includes(analyticsCookieCategory)) {
          setCookieConsent(hasAnalyticsConsent(cookie))
        }
      },
      onModalHide: ({ modalName }) => {
        const modalElement = document.querySelector(modalName)
        if (modalElement) {
          modalElement.remove()
        }
      },

      language: {
        default: "en",
        translations: {
          en: {
            consentModal: {
              title: "Používáme cookies",
              description:
                'Soubory cookie používáme k&nbsp;analýze údajů o&nbsp;našich návštěvnících a&nbsp;ke&nbsp;zlepšení našich webových stránek, abychom vám poskytli skvělý zážitek z&nbsp;webu a&nbsp;registrací na&nbsp;závody. <a target="_blank" rel="noopener noreferrer" href="https://www.nazavody.cz/ochrana-soukromi">Více o&nbsp;cookies</a>.',
              acceptAllBtn: "Přijmout všechny",
              acceptNecessaryBtn: "Odmítnout všechny",
              showPreferencesBtn: "Podrobné nastavení"
            },
            preferencesModal: {
              title: "Podrobné nastavení cookies",
              acceptAllBtn: "Přijmout všechny",
              acceptNecessaryBtn: "Odmítnout všechny",
              savePreferencesBtn: "Uložit",
              closeIconLabel: "Zavřít modal",
              sections: [
                {
                  title: "Co jsou cookies?",
                  description:
                    'Soubory cookie používáme k&nbsp;analýze údajů o&nbsp;našich návštěvnících a&nbsp;ke&nbsp;zlepšení našich webových stránek, abychom vám poskytli skvělý zážitek z&nbsp;webu a&nbsp;registrací na&nbsp;závody. <br /> <a target="_blank" rel="noopener noreferrer" href="https://www.nazavody.cz/ochrana-soukromi">Více o&nbsp;cookies</a>.'
                },
                {
                  title: "Funkční cookies",
                  description:
                    "Tyto soubory cookie jsou nezbytné pro fungování webových stránek, není tedy možné je zakázat. Obvykle se nastavují v reakci na akci, kterou na webu sami provedete, jako je nastavení zabezpečení, přihlášení a vyplňování formulářů. Svůj prohlížeč můžete nastavit tak, aby blokoval soubory cookie nebo o nich zasílal upozornění. Tyto soubory cookie neukládají žádné informace, které lze přiřadit konkrétní osobě. Tyto soubory cookie můžeme nastavit my nebo poskytovatelé třetích stran, jejichž služby na webu využíváme. Tyto soubory cookie neukládají žádné informace, které lze přiřadit konkrétní osobě.",
                  linkedCategory: "necessary",
                  cookieTable: {
                    headers: {
                      name: "Cookie",
                      domain: "Doména",
                      desc: "Popisek",
                      expiration: "Platnost",
                      author: "Zpracovatel"
                    },
                    body: [
                      {
                        name: "cc_cookie",
                        domain: "nazavody.cz",
                        desc: "Ukládá souhlas uživatele s používáním cookies.",
                        expiration: "182 dní",
                        author: "CRS a.s."
                      },
                      {
                        name: "_csrf",
                        domain: "nazavody.cz",
                        desc: "Soubor cookie _csrf se používá jako bezpečnostní opatření k prevenci útoků typu Cross-Site Request Forgery (CSRF). Tyto útoky umožňují zlomyslným webovým stránkám způsobit, že prohlížeč uživatele provede nežádoucí akci na jiném webu, kde je uživatel ověřen, a to bez jeho vědomí. _csrf cookie obsahuje token, který server ověřuje, aby zajistil, že požadavky odeslané na server jsou záměrné a legitimní akce uživatele.",
                        expiration: "relace",
                        author: "naZavody.cz"
                      },
                      {
                        name: "session",
                        domain: "nazavody.cz",
                        desc: "Udržuje přihlášení organizátora do systému.",
                        expiration: "relace",
                        author: "naZavody.cz"
                      },
                      {
                        name: "session.sig",
                        domain: "nazavody.cz",
                        desc: "Udržuje přihlášení organizátora do systému.",
                        expiration: "relace",
                        author: "naZavody.cz"
                      },
                      {
                        name: "ref-race-*",
                        domain: "nazavody.cz",
                        desc: "Soubor cookie slouží k identifikaci pozvaného závodníka po udělení souhlasu při pozvání.",
                        expiration: "2 roky",
                        author: "naZavody.cz"
                      },
                      {
                        name: "ref-*",
                        domain: "nazavody.cz",
                        desc: "Soubor cookie slouží k identifikaci pozvaného závodníka po udělení souhlasu při pozvání.",
                        expiration: "2 roky",
                        author: "naZavody.cz"
                      }
                    ]
                  }
                },
                {
                  title: "Analytické cookies",
                  description:
                    "Tyto soubory cookie se používají ke zlepšení fungování webových stránek. Umožňují nám a organizátorům závodů rozpoznat a sledovat počet návštěvníků a sledovat, jak návštěvníci web používají. Pomáhají nám zlepšovat způsob, jakým webové stránky fungují, například tím, že uživatelům umožňují snadno najít to, co hledají. Tyto soubory cookie neshromažďují informace, které by vás mohly identifikovat. Pomocí těchto nástrojů analyzujeme a pravidelně zlepšujeme funkčnost našich webových stránek. Získané statistiky můžeme využít ke zlepšení uživatelského komfortu a k tomu, aby byla návštěva webu pro vás jako uživatele zajímavější. Tyto analytické soubory cookie budou použity pouze s vaším souhlasem.",
                  linkedCategory: "analytics",
                  cookieTable: {
                    headers: {
                      name: "Cookie",
                      domain: "Doména",
                      desc: "Popisek",
                      expiration: "Platnost",
                      author: "Zpracovatel"
                    },
                    body: [
                      {
                        name: "_ga",
                        domain: "nazavody.cz",
                        desc: "Google Analytics používá tento soubor cookie k výpočtu dat o návštěvnících, relacích a kampaních a sledování využití webu pro analytické zprávy stránek. Cookie ukládá informace anonymně a přiřazuje náhodně generované číslo k rozpoznání jedinečných návštěvníků.",
                        expiration: "2 roky",
                        author: "Google"
                      },
                      {
                        name: " _ga_*",
                        domain: "nazavody.cz",
                        desc: "Google Analytics používá tento soubor cookie k výpočtu dat o návštěvnících, relacích a kampaních a sledování využití webu pro analytické zprávy stránek. Cookie ukládá informace anonymně a přiřazuje náhodně generované číslo k rozpoznání jedinečných návštěvníků.",
                        expiration: "2 roky",
                        author: "Google"
                      }
                    ]
                  }
                },
                {
                  title: "Více informací",
                  description: 'V případě jakýchkoliv dodazů nás neváhejte <a href="mailto:info@nazavody.cz">kontaktovat</a>.'
                }
              ]
            }
          }
        }
      }
    })

    const resetModalState = () => {
      CookiesConsent.hide()
      if (!CookiesConsent.validConsent()) {
        setTimeout(() => CookiesConsent.show(), 300)
      }
    }

    resetModalState()
  }, [location, setCookieConsent])

  return null
}

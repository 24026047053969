import ComputerIcon from "@website/svg/icons/ComputerIcon"
import MoonIcon from "@website/svg/icons/MoonIcon"
import SunIcon from "@website/svg/icons/SunIcon"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { Button, DialogTrigger, Popover, Menu, MenuItem, Dialog, Text, Separator } from "react-aria-components"
import type { Selection } from "react-aria-components"
import { Theme } from "@infrastructure/themes/theme"
import { useTheme } from "@infrastructure/themes/useTheme"

const triggerButton = classNames(
  `ml-auto block appearance-none bg-transparent outline-none transition-colors duration-200 ease-in-out hover:text-brand-primary focus:ring-2 focus:ring-brand-primary focus-visible:ring-2 focus-visible:ring-brand-primary pressed:text-brand-primary dark:hover:text-brand-dark-primary dark:pressed:text-brand-dark-primary`
)
const menuClasses = classNames(`mt-2 rounded-md border border-gray-300 bg-white p-1 dark:border-gray-300/30 dark:bg-slate-900 dark:text-slate-200`)
const menuItemClasses = classNames(
  `flex cursor-pointer items-center gap-x-2 rounded-md p-2 text-sm outline-none hover:bg-brand-secondary/10 focus:ring-2 focus:ring-brand-primary focus-visible:ring-2 focus-visible:ring-brand-primary selected:text-brand-primary hover:dark:bg-brand-dark-primary/40 dark:selected:bg-brand-dark-primary/50 dark:selected:text-slate-200`
)
const menuItemMobileClasses = classNames(
  `flex cursor-pointer items-center justify-end gap-x-2 rounded-md py-2 pl-2 text-right text-sm outline-none selected:text-brand-primary dark:selected:text-brand-dark-primary`
)

export default function ThemeSwitcher() {
  const { theme, setTheme, resolvedTheme } = useTheme()
  const [selectedTheme, setSelectedTheme] = useState<Selection>(new Set([theme || Theme.system]))
  const [darkMode, setDarkMode] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const handleThemeChange = (newTheme: string) => {
    setSelectedTheme(new Set([newTheme]))
    setTheme(newTheme as Theme)
  }

  useEffect(() => {
    if (resolvedTheme === Theme.dark) {
      setDarkMode(true)
    } else {
      setDarkMode(false)
    }
  }, [resolvedTheme])

  useEffect(() => {
    setSelectedTheme(new Set([theme]))
  }, [theme])

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  const renderListBox = (mobile = false) => (
    <Menu
      aria-label="Menu se světlým / tmavým módem"
      defaultSelectedKeys={selectedTheme}
      selectionMode="single"
      className={mobile ? "" : menuClasses}
      selectedKeys={selectedTheme}
      onSelectionChange={keys => {
        const newTheme = Array.from(keys)[0]
        if (typeof newTheme === "string") {
          handleThemeChange(newTheme)
        }
      }}
    >
      <MenuItem aria-label="Světlý mód" id={Theme.light} className={mobile ? menuItemMobileClasses : menuItemClasses}>
        <SunIcon classnames={`w-5 h-5 ${mobile ? "order-2" : ""}`} />
        <Text slot="label">Světlý mód</Text>
      </MenuItem>
      <MenuItem aria-label="Tmavý mód" id={Theme.dark} className={mobile ? menuItemMobileClasses : menuItemClasses}>
        <MoonIcon classnames={`w-5 h-4 ${mobile ? "order-2" : ""}`} />
        <Text slot="label">Tmavý mód</Text>
      </MenuItem>
      <MenuItem aria-label="Podle systému" id={Theme.system} className={mobile ? menuItemMobileClasses : menuItemClasses}>
        <ComputerIcon classnames={`w-5 h-4 ${mobile ? "order-2" : ""}`} />
        <Text slot="label">Podle systému</Text>
      </MenuItem>
    </Menu>
  )

  return (
    <>
      <div className="hidden lg:block">
        <DialogTrigger>
          <Button id="menu" aria-label="Světlý / tmavý mód" className={triggerButton}>
            <MoonIcon classnames={`w-5 h-5 ${darkMode ? "block" : "hidden"}`} /> <SunIcon classnames={`w-5 h-5 ${darkMode ? "hidden" : "block"}`} />
          </Button>
          <Popover>
            <Dialog className="outline-none">{renderListBox()}</Dialog>
          </Popover>
        </DialogTrigger>
      </div>
      <div className="lg:hidden">
        <Separator className={classNames("my-2 border-t border-gray-200 dark:border-gray-200/30")} />
        {isLoaded && renderListBox(true)}
      </div>
    </>
  )
}

/* eslint-disable */
export const admin1x = require("../for-organizers/admin@1x.webp")
export const admin2x = require("../for-organizers/admin@2x.webp")
export const team1x = require("../for-organizers/team@1x.webp")
export const team2x = require("../for-organizers/team@2x.webp")
export const deviceLight1x = require("../for-organizers/device-light@1x.webp")
export const deviceLight2x = require("../for-organizers/device-light@2x.webp")
export const deviceDark1x = require("../for-organizers/device-dark@1x.webp")
export const deviceDark2x = require("../for-organizers/device-dark@2x.webp")
export const startFlag = require("../for-organizers/flag.svg")
export const adminCropped1x = require("../home/admin-cropped@1x.webp")
export const adminCropped2x = require("../home/admin-cropped@2x.webp")
export const background = require("../layout/background.svg")
export const hornettlon1_1x = require("../organizers-references/hornettlon-1@1x.webp")
export const hornettlon1_2x = require("../organizers-references/hornettlon-1@2x.webp")
export const hornettlon2_1x = require("../organizers-references/hornettlon-2@1x.webp")
export const hornettlon2_2x = require("../organizers-references/hornettlon-2@2x.webp")
export const hornettlon3_1x = require("../organizers-references/hornettlon-3@1x.webp")
export const hornettlon3_2x = require("../organizers-references/hornettlon-3@2x.webp")
export const ask_decin1_1x = require("../organizers-references/ask-decin-1@1x.webp")
export const ask_decin1_2x = require("../organizers-references/ask-decin-1@2x.webp")
export const ask_decin2_1x = require("../organizers-references/ask-decin-2@1x.webp")
export const ask_decin2_2x = require("../organizers-references/ask-decin-2@2x.webp")
export const ask_decin3_1x = require("../organizers-references/ask-decin-3@1x.webp")
export const ask_decin3_2x = require("../organizers-references/ask-decin-3@2x.webp")
export const ask_decin_petr_hornik_1x = require("../for-organizers/ask-decin-petr-hornik@1x.webp")
export const ask_decin_petr_hornik_2x = require("../for-organizers/ask-decin-petr-hornik@2x.webp")
export const ask_decin_logo_1x = require("../for-organizers/ask-decin-logo@1x.webp")
export const ask_decin_logo_2x = require("../for-organizers/ask-decin-logo@2x.webp")
export const ludgerovice1_1x = require("../organizers-references/ludgerovice-1@1x.webp")
export const ludgerovice1_2x = require("../organizers-references/ludgerovice-1@2x.webp")
export const ludgerovice2_1x = require("../organizers-references/ludgerovice-2@1x.webp")
export const ludgerovice2_2x = require("../organizers-references/ludgerovice-2@2x.webp")
export const ludgerovice3_1x = require("../organizers-references/ludgerovice-3@1x.webp")
export const ludgerovice3_2x = require("../organizers-references/ludgerovice-3@2x.webp")
export const ludgerovice_david_bibrlik_1x = require("../for-organizers/ludgerovice-david-bibrlik@1x.webp")
export const ludgerovice_david_bibrlik_2x = require("../for-organizers/ludgerovice-david-bibrlik@2x.webp")
export const ludgerovice_logo_1x = require("../for-organizers/ludgerovice-logo@1x.webp")
export const ludgerovice_logo_2x = require("../for-organizers/ludgerovice-logo@2x.webp")
/* eslint-enable */

import { Helmet } from "react-helmet"

interface Props {
  title?: string
  description?: string | null
  image?: string | null
  noIndex?: boolean
}

const Meta = (props: Props) => (
  <Helmet titleTemplate="%s | naZávody.cz">
    {props.title && <title>{props.title}</title>}
    {props.description && <meta name="description" content={props.description} />}
    {props.title && <meta property="og:title" content={props.title} />}
    {props.description && <meta property="og:description" content={props.description} />}
    {props.image && <meta property="og:image" content={props.image} />}
    {props.noIndex && <meta name="robots" content="noindex" />}
  </Helmet>
)

export default Meta

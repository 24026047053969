import { InlineSpinner } from "../InlineSpinner"
import { Delayed } from "../Delayed"
import classNames from "classnames"
import ButtonLoadingWrapper from "../ButtonLoadingWrapper/ButtonLoadingWrapper"
import { Link } from "react-router-dom"

const buttonClasses = classNames(`
  inline-flex cursor-pointer appearance-none items-center justify-center gap-x-2 rounded border font-bold uppercase transition-colors duration-200 ease-in-out disabled:cursor-not-allowed disabled:border-gray-400 disabled:bg-gray-400 disabled:opacity-50 hover:disabled:border-gray-400 hover:disabled:bg-gray-400`)
const buttonInputGroupClasses = classNames(`rounded-l-none`)
const colorBlackClasses = classNames(`border-black bg-black text-white hover:border-black/80 hover:bg-black/80 dark:border-slate-200 dark:hover:border-slate-200/60`)
const colorDangerClasses = classNames(`border-red-700 bg-red-700 text-white hover:border-red-500 hover:bg-red-500`)
const colorDangerInvertedClasses = classNames(`border-red-700 bg-white text-red-700 hover:border-red-700 hover:bg-red-700 hover:text-white`)
const colorEmailClasses = classNames(`border-sky-500 bg-sky-500 text-white hover:border-sky-500/80 hover:bg-sky-500/80`)
const colorFacebookClasses = classNames(`border-facebook bg-facebook text-white hover:border-facebook/80 hover:bg-facebook/80`)
const colorInvertedClasses = classNames(
  `border-white bg-white text-brand-text-secondary hover:border-white hover:bg-transparent hover:text-brand-text-primary dark:text-brand-dark-text-secondary dark:hover:text-brand-dark-text-primary`
)
const colorInvertedOnlyHoverClasses = classNames(
  `border-brand-primary bg-brand-primary text-brand-text-primary hover:border-white hover:bg-white hover:text-brand-text-secondary dark:border-brand-dark-primary dark:bg-brand-dark-primary dark:text-brand-dark-text-primary dark:hover:border-white dark:hover:bg-white dark:hover:text-brand-dark-text-secondary`
)
const colorOutlinedClasses = classNames(
  `border-brand-primary bg-transparent text-brand-primary hover:bg-brand-primary hover:text-brand-text-primary dark:border-brand-dark-primary dark:text-brand-dark-primary dark:hover:border-brand-dark-primary dark:hover:text-brand-dark-text-primary`
)
const colorPrimaryClasses = classNames(
  `border-brand-primary bg-brand-primary text-brand-text-primary hover:border-brand-secondary hover:bg-brand-secondary dark:border-brand-dark-primary dark:bg-brand-dark-primary dark:text-brand-text-primary dark:hover:border-brand-dark-secondary dark:hover:bg-brand-dark-secondary`
)
const noRadiusClasses = classNames(`rounded-none`)
const sizeDefaultClasses = classNames(`px-3.5 py-1.5 leading-6 sm:px-4 sm:py-2`)
const sizeLargeClasses = classNames(`px-4 py-2 sm:px-5 sm:py-3`)

const styleClassesMap = {
  black: colorBlackClasses,
  danger: colorDangerClasses,
  dangerInverted: colorDangerInvertedClasses,
  email: colorEmailClasses,
  facebook: colorFacebookClasses,
  inverted: colorInvertedClasses,
  invertedOnlyHover: colorInvertedOnlyHoverClasses,
  outlined: colorOutlinedClasses,
  primary: colorPrimaryClasses
}

const sizeClassesMap = {
  default: sizeDefaultClasses,
  large: sizeLargeClasses
}

interface ButtonBaseProps {
  children: React.ReactNode
  loading?: boolean
  noRadius?: boolean
  size?: "default" | "large"
  style?: "primary" | "outlined" | "inverted" | "invertedOnlyHover" | "danger" | "dangerInverted" | "facebook" | "black" | "email"
}

interface ButtonProps extends ButtonBaseProps {
  onClick?: (event: React.MouseEvent) => void
  type?: "button" | "submit" | "reset"
  disabled?: boolean
  inputGroup?: boolean
}

interface ButtonHrefProps extends ButtonBaseProps {
  href: string
  openInNewWindow?: boolean
}

interface ButtonLinkProps extends ButtonBaseProps {
  to: string
}

export function Button({ children, loading, noRadius, size = "default", style = "primary", disabled, onClick, type, inputGroup = false }: ButtonProps) {
  return (
    <button
      type={type || "button"}
      disabled={disabled}
      className={classNames(buttonClasses, styleClassesMap[style], sizeClassesMap[size], noRadius && noRadiusClasses, inputGroup && buttonInputGroupClasses)}
      onClick={onClick}
    >
      {loading && (
        <Delayed condition={loading}>
          <InlineSpinner />
        </Delayed>
      )}
      {children}
    </button>
  )
}

export function ButtonHref({ children, loading, href, noRadius, size = "default", style = "primary", openInNewWindow = false }: ButtonHrefProps) {
  return (
    <a
      className={classNames(buttonClasses, styleClassesMap[style], sizeClassesMap[size], noRadius && noRadiusClasses)}
      href={href}
      rel={openInNewWindow ? "noopener noreferrer" : undefined}
      target={openInNewWindow ? "_blank" : undefined}
    >
      <ButtonLoadingWrapper loading={loading}>{children}</ButtonLoadingWrapper>
    </a>
  )
}

export function ButtonLink({ children, loading, noRadius, size = "default", style = "primary", to }: ButtonLinkProps) {
  return (
    <Link className={classNames(buttonClasses, styleClassesMap[style], sizeClassesMap[size], noRadius && noRadiusClasses)} to={to}>
      <ButtonLoadingWrapper loading={loading}>{children}</ButtonLoadingWrapper>
    </Link>
  )
}

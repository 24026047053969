import { useCallback, useEffect, useState } from "react"
import { Theme, themeCookieName } from "./theme"
import { getServerContext } from "@infrastructure/afterjs/serverContext"

export const useTheme = () => {
  const defaultThemes = getDefaultThemes()
  const [forcedTheme, setForcedTheme] = useState(defaultThemes.theme)
  const [resolvedTheme, setResolvedTheme] = useState(defaultThemes.resolvedTheme)

  const applyTheme = useCallback((theme: Theme) => {
    if (theme === Theme.system) {
      const resolved = getSystemTheme()
      applyThemeToDocument(resolved)
      return resolved
    }
    applyThemeToDocument(theme)
    return theme
  }, [])

  const handleMediaQuery = useCallback(
    (_e: MediaQueryListEvent | MediaQueryList) => {
      if (forcedTheme === "system") {
        const resolved = applyTheme(Theme.system)
        setResolvedTheme(resolved)
      }
    },
    [applyTheme, forcedTheme]
  )

  // Always listen to System preference
  useEffect(() => {
    const media = window.matchMedia(MEDIA)

    // Intentionally use deprecated listener methods to support iOS & old browsers
    // eslint-disable-next-line deprecation/deprecation
    media.addListener(handleMediaQuery)
    handleMediaQuery(media)

    // eslint-disable-next-line deprecation/deprecation
    return () => media.removeListener(handleMediaQuery)
  }, [handleMediaQuery])

  const setThemeCallback = useCallback(
    (theme: Theme) => {
      const resolved = applyTheme(theme)
      setForcedTheme(theme)
      setForcedTheme(theme)
      setCookie(themeCookieName, theme, { path: "/" })
      sessionStorage.setItem(themeCookieName, theme)
      setResolvedTheme(resolved)
    },
    [applyTheme]
  )

  useEffect(() => {
    const savedTheme = sessionStorage.getItem(themeCookieName) as Theme
    if (savedTheme && Object.values(Theme).includes(savedTheme)) {
      setThemeCallback(savedTheme)
    }
  }, [setThemeCallback])

  return {
    theme: forcedTheme,
    setTheme: setThemeCallback,
    resolvedTheme
  }
}

const MEDIA = "(prefers-color-scheme: dark)"

const applyThemeToDocument = (theme: Theme) => {
  const d = document.documentElement
  d.style.colorScheme = theme
  d.setAttribute("data-theme", theme)
}

const getSystemTheme = (e?: MediaQueryList | MediaQueryListEvent): Theme => {
  if (!e) e = window.matchMedia(MEDIA)
  const isDark = e.matches
  return isDark ? Theme.dark : Theme.light
}

const setCookie = (name: string, value: string, options: { path: string }) => {
  document.cookie = `${name}=${value};path=${options.path};Secure;SameSite=Lax`
}

export const getDefaultThemes = () => {
  const selectedTheme = getServerContext().theme
  if (selectedTheme === Theme.system) {
    return { theme: Theme.system, resolvedTheme: getServerContext().systemTheme ?? Theme.system }
  }
  return { theme: selectedTheme, resolvedTheme: selectedTheme }
}

import { Link } from "react-router-dom"
import { routes } from "../../routes"
import Logo from "../../svg/images/Logo"
import Hamburger from "../Hamburger/Hamburger"
import { MenuLink, ExternalMenuLink } from "../MenuLink/MenuLink"
import Container from "@website/components/Container/Container"
import classNames from "classnames"
import { useState } from "react"
import ThemeSwitcher from "@website/components/ThemeSwitcher/ThemeSwitcher"

const largeNavLinksClasses = classNames(`hidden lg:flex lg:items-center lg:gap-x-8`)
const navbarClasses = classNames(`flex flex-wrap items-start justify-between py-4 lg:items-center lg:py-6`)
const smallNavLinksClasses = classNames(`flex flex-col gap-y-3 overflow-hidden text-right transition-all duration-200 ease-in-out`)
const smallNavLinksCloseClasses = classNames(`max-h-0 opacity-0`)
const smallNavLinksOpenClasses = classNames(`max-h-80 pb-1 pt-3 opacity-100`)
const smallNavMenuClasses = classNames(`flex flex-1 flex-col text-right lg:hidden`)
const toggleClasses = classNames(`flex cursor-pointer appearance-none items-center justify-end gap-x-2.5 border-0 bg-transparent p-0 focus:bg-transparent`)
const toggleTextClasses = classNames(`inline-block`)
const topNavigationClasses = classNames(`relative overflow-hidden border-b border-gray-100 bg-white shadow-default dark:border-gray-100/10 dark:bg-transparent`)

interface TopNavigationProps {
  shadow?: boolean
}

function renderItems() {
  return (
    <>
      <li>
        <MenuLink to={routes.allRaces.getLink()}>Všechny závody</MenuLink>
      </li>
      <li>
        <MenuLink to={routes.forRacers.getLink()}>Pro závodníky</MenuLink>
      </li>
      <li>
        <MenuLink to={routes.forOrganizers.getLink()}>Pro organizátory</MenuLink>
      </li>
      <li>
        <ExternalMenuLink to="https://blog.nazavody.cz">Blog</ExternalMenuLink>
      </li>
      <li>
        <MenuLink to={routes.contact.getLink()}>Kontakt</MenuLink>
      </li>
      <li>
        <ThemeSwitcher />
      </li>
    </>
  )
}

export default function TopNavigation({ shadow }: TopNavigationProps) {
  const [menuShown, setMenuShown] = useState(false)
  const toggle = () => {
    setMenuShown(!menuShown)
  }

  return (
    <div className={classNames(shadow && topNavigationClasses)}>
      <Container>
        <nav className={navbarClasses}>
          <Link to={routes.home.getLink()}>
            <Logo />
          </Link>
          <ul className={largeNavLinksClasses}>{renderItems()}</ul>
          <div className={smallNavMenuClasses}>
            <button className={toggleClasses} type="button" onClick={toggle}>
              <span className={toggleTextClasses}>{menuShown ? "Zavřít" : "Menu"}</span>
              <Hamburger open={menuShown} />
            </button>
            <ul className={classNames(menuShown ? smallNavLinksOpenClasses : smallNavLinksCloseClasses, smallNavLinksClasses)}>{renderItems()}</ul>
          </div>
        </nav>
      </Container>
    </div>
  )
}

import { routes } from "@organizer/routes"
import { ButtonHref } from "@website/components/Button/Button"
import Container from "@website/components/Container/Container"
import ArrowSmallRightIcon from "@website/svg/icons/ArrowSmallRightIcon"
import classNames from "classnames"

const organizerLinkTopBarClasses = classNames(`bg-brand-secondary dark:bg-brand-dark-secondary sm:text-right`)

export const OrganizerLinkTopBar: React.FC<{ isLogged: boolean }> = ({ isLogged }) => (
  <div className={organizerLinkTopBarClasses}>
    <Container>
      <ButtonHref href={routes.organizer.getLink()} style="invertedOnlyHover" noRadius>
        {isLogged ? "Zpět do administrace závodů" : "Přihlášení pro organizátory"}
        <ArrowSmallRightIcon classnames="w-6 h-6" />
      </ButtonHref>
    </Container>
  </div>
)

import classNames from "classnames"
import { NavLink } from "react-router-dom"

const linkClasses = classNames(
  `relative underline decoration-transparent decoration-2 underline-offset-2 transition-colors duration-200 ease-in-out hover:text-brand-primary hover:decoration-brand-primary dark:hover:text-brand-dark-primary dark:hover:decoration-brand-primary`
)
const linkActiveClasses = classNames(`text-brand-primary decoration-brand-primary dark:text-brand-dark-primary dark:decoration-brand-dark-primary`)

interface MenuLinkProps {
  to: string
  children: React.ReactNode
}

export function MenuLink({ to, children }: MenuLinkProps) {
  return (
    <NavLink className={linkClasses} activeClassName={linkActiveClasses} to={to} exact>
      {children}
    </NavLink>
  )
}

export function ExternalMenuLink({ to, children }: MenuLinkProps) {
  return (
    <a className={linkClasses} href={to}>
      {children}
    </a>
  )
}

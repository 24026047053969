import { getServerContext } from "@infrastructure/afterjs/serverContext"
import React, { useEffect, useState } from "react"
import ReactGA from "react-ga4"
import { useLocation } from "react-router"
import { hasAnalyticsConsent } from "./analyticsConsent"

interface GaWrapperProps {
  children: React.ReactNode
  cookieConsent: boolean
}

export default function GaWrapper({ children, cookieConsent }: GaWrapperProps) {
  const location = useLocation()
  const [analyticsInitialized, setAnalyticsInitialized] = useState(false)

  const checkCookieConsent = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find(row => row.startsWith("cc_cookie="))
      ?.split("=")[1]

    const consentInfo = cookieValue ? JSON.parse(decodeURIComponent(cookieValue)) : null
    if (!consentInfo) {
      return false
    }
    return hasAnalyticsConsent(consentInfo)
  }

  useEffect(() => {
    if (cookieConsent || checkCookieConsent()) {
      ReactGA.initialize(getServerContext().gaTrackingId)
      setAnalyticsInitialized(true)
    } else {
      setAnalyticsInitialized(false)
    }
  }, [cookieConsent])

  useEffect(() => {
    if (analyticsInitialized) {
      const currentPage = location.pathname + location.search
      ReactGA.send({ hitType: "pageview", page: currentPage })
    }
  }, [location, analyticsInitialized])

  return <>{children}</>
}

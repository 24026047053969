import classNames from "classnames"
import { routes } from "../../routes"
import Container from "@website/components/Container/Container"
import Heading from "@website/components/Heading/Heading"
import Link, { ExternalLink, LinkWithHash } from "@website/components/Link/Link"
import FacebookCircleIcon from "@website/svg/icons/FacebookCircleIcon"
import YoutubeIcon from "@website/svg/icons/YoutubeIcon"
import InstagramIcon from "@website/svg/icons/InstagramIcon"
import * as CookiesConsent from "vanilla-cookieconsent"
import { routes as orgRoutes } from "@organizer/routes"
import LinkWithArrow from "@website/components/LinkWithArrow/LinkWithArrow"
import StravaIcon from "@website/svg/icons/StravaIcon"
import Flag from "@website/svg/images/Flag"

const gridClasses = classNames(
  `mt-10 grid gap-y-12 border-t border-t-gray-200 pb-8 pt-2 dark:border-t-2 dark:border-t-gray-100/10 sm:mt-14 md:pb-10 md:pt-6 lg:mt-16 lg:gap-8 lg:pt-10 xl:grid-cols-1-3 xl:gap-x-12`
)
const linksClasses = classNames(`columns-2 gap-x-4 gap-y-6 text-sm md:text-base lg:order-2 lg:mt-4 lg:grid lg:columns-auto lg:grid-cols-4 lg:gap-x-8 xl:mt-0 xl:flex xl:justify-between`)
const listClasses = classNames(`mt-4 flex break-inside-avoid-column flex-col gap-y-4`)
const copyrightClasses = classNames(`border-t border-t-gray-100 py-6 text-sm dark:border-t-gray-100/10`)
const flagImageClasses = classNames(`w-9 lg:-rotate-45 lg:-scale-x-100 lg:scale-y-100 2xl:absolute 2xl:-left-14 2xl:-top-2`)
const socialLinksClasses = classNames(`mt-4 flex flex-wrap items-center gap-x-6 lg:mt-1`)
const linkClassButtonClasses = classNames(`appearance-none bg-transparent p-0 text-left text-gray-800 dark:text-slate-200`)
const linkClassButtonDecorationClasses = classNames(
  `underline decoration-brand-primary decoration-2 underline-offset-2 transition-colors duration-200 ease-in-out hover:decoration-transparent dark:decoration-brand-dark-primary dark:hover:decoration-transparent`
)

export default function Footer() {
  return (
    <footer>
      <Container>
        <div className={gridClasses}>
          <div className="flex flex-col lg:gap-y-4 relative order-2 lg:order:1">
            <div className="flex flex-col lg:flex-row gap-x-2 gap-y-3 lg:items-center lg:-ml-4 xl:ml-0">
              <div className={flagImageClasses}>
                <Flag />
              </div>
              <Heading type="h4" brand>
                naZávody.cz
              </Heading>
            </div>
            <p className="text-sm md:text-base leading-snug max-w-[75%] sm:max-w-none md:leading-relaxed">Pomáháme organizátorům vyřešit vše&nbsp;kolem registrací na&nbsp;závody.</p>
            <div className={socialLinksClasses}>
              <ExternalLink to="https://www.facebook.com/nazavodycz" title="Facebook profil naZávody.cz">
                <FacebookCircleIcon classnames="w-7 h-7 fill-slate-400 stroke-transparent hover:fill-brand-secondary transition-colors duration-200 ease-in-out" />
              </ExternalLink>
              <ExternalLink to="https://www.instagram.com/nazavodycz/" title="Instagram profil naZávody.cz">
                <InstagramIcon classnames="w-7 h-7 fill-slate-400 stroke-transparent hover:fill-brand-secondary transition-colors duration-200 ease-in-out" />
              </ExternalLink>
              <ExternalLink to="https://www.youtube.com/@naZavodycz/videos" title="YouTube kanál naZávody.cz">
                <YoutubeIcon classnames="w-7 h-7 stroke-slate-400 hover:stroke-brand-secondary transition-colors duration-200 ease-in-out" />
              </ExternalLink>
              <ExternalLink to="https://www.strava.com/clubs/979841" title="Strava Club naZávody.cz">
                <StravaIcon classnames="w-6 h-6 stroke-slate-400 hover:stroke-brand-secondary transition-colors duration-200 ease-in-out" />
              </ExternalLink>
            </div>
          </div>
          <div className={linksClasses}>
            <div className="pt-8 lg:pt-0">
              <Heading type="h5">Pro závodníky</Heading>
              <ul className={listClasses}>
                <li>
                  <Link to={routes.allRaces.getLink()}>Všechny závody</Link>
                </li>
                <li>
                  <Link to={routes.forRacers.getLink()}>Registrované závody</Link>
                </li>
              </ul>
            </div>
            <div className="pt-8 lg:pt-0">
              <Heading type="h5">Pro organizátory</Heading>
              <ul className={listClasses}>
                <li>
                  <LinkWithArrow arrow="right" to={orgRoutes.organizer.getLink()}>
                    Příhlášení do systému
                  </LinkWithArrow>
                </li>
                <li>
                  <Link to={routes.forOrganizers.getLink()}>Proč zvolit naZávody.cz</Link>
                </li>
                <li>
                  <LinkWithHash to={`${routes.forOrganizers.getLink()}#vice-informaci`}>Funkce systému</LinkWithHash>
                </li>
                <li>
                  <LinkWithHash to={`${routes.forOrganizers.getLink()}#cenik`}>Ceník</LinkWithHash>
                </li>
                <li>
                  <LinkWithHash to={`${routes.forOrganizers.getLink()}#zkusenosti-organizatoru`}>Zkušenosti organizátorů</LinkWithHash>
                </li>
                <li>
                  <LinkWithHash to={`${routes.forOrganizers.getLink()}#casto-kladene-dotazy`}>Časté dotazy</LinkWithHash>
                </li>
                <li>
                  <Link to={routes.changelog.getLink()}>Novinky v systému</Link>
                </li>
                <li>
                  <Link to={routes.terms.getLink()}>Obchodní podmínky</Link>
                </li>
              </ul>
            </div>
            <div className="pt-8 lg:pt-0">
              <Heading type="h5">Všeobecné</Heading>
              <ul className={listClasses}>
                <li>
                  <Link to={routes.privacy.getLink()}>Ochrana osobních údajů</Link>
                </li>
                <li>
                  <button className={linkClassButtonClasses} type="button" onClick={() => CookiesConsent.show(true)}>
                    <span className={linkClassButtonDecorationClasses}>Nastavení cookies</span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="pt-8 lg:pt-0">
              <Heading type="h5">O nás</Heading>
              <ul className={listClasses}>
                <li>
                  <Link to={routes.contact.getLink()}>Kontakt</Link>
                </li>
                <li>
                  <Link to={routes.feedbackHornettlon.getLink()}>Náš příběh</Link>
                </li>
                <li>
                  <ExternalLink to="https://blog.nazavody.cz">Blog</ExternalLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={copyrightClasses}>
          <p>naZavody.cz s.r.o. &copy; {new Date().getFullYear()}</p>
        </div>
      </Container>
    </footer>
  )
}

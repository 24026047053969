import { InlineSpinner } from "../InlineSpinner"
import { Delayed } from "../Delayed"

interface ButtonLoadingWrapperProps {
  children: React.ReactNode
  loading?: boolean
}

export default function ButtonLoadingWrapper({ children, loading }: ButtonLoadingWrapperProps) {
  return (
    <>
      {loading && (
        <Delayed condition={loading}>
          <InlineSpinner />
        </Delayed>
      )}
      {children}
    </>
  )
}

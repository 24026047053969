import TopNavigation from "../TopNavigation/TopNavigation"
import { Helmet } from "react-helmet"
import Meta from "../Meta"
import { images } from "../images"
import { background as backgroundImage } from "@website/components/Images"
import { OrganizerLinkTopBar } from "../OrganizerLinkTopBar/OrganizerLinkTopBar"
import { getServerContext } from "@infrastructure/afterjs/serverContext"
import { useEffect, useState } from "react"
import Footer from "../Footer/Footer"
import classNames from "classnames"
import "vanilla-cookieconsent/dist/cookieconsent.css"
import "../../../base.css"
import CookieConsentWrapper from "@infrastructure/CookieConsentWrapper/CookieConsentWrapper"
import { SSRProvider } from "react-aria"

const backgroundClasses = classNames(`absolute inset-x-0 top-14 -z-10 mx-auto block opacity-20 blur-2xl`)
const mainClasses = classNames(`lg:pt-16`)
const paddingOnMobileSmallClasses = classNames(`pt-3 xs:pt-6`)
const paddingOnMobileMediumClasses = classNames(`pt-10`)
const rootClasses = classNames(`relative grid min-h-screen grid-cols-w-screen grid-rows-body-layout`)
const withBackgroundClasses = classNames(`relative pt-10 md:pt-28`)

interface LayoutProps {
  alwaysShowOrganizerLinkBar?: boolean
  background?: boolean
  headerWithShadow?: boolean
  children: any
  paddingOnMobile?: "small" | "medium"
  smoothScroll?: boolean
}

export default function Layout({ alwaysShowOrganizerLinkBar, background, children, headerWithShadow = true, paddingOnMobile = "small", smoothScroll = false }: LayoutProps) {
  const [isLogged, setIsLogged] = useState(false)

  useEffect(() => {
    setIsLogged(!!getServerContext().user)
  }, [])

  return (
    <SSRProvider>
      <CookieConsentWrapper>
        <div className={rootClasses}>
          <Helmet
            htmlAttributes={{
              lang: "cs",
              class: smoothScroll ? "scroll-smooth" : ""
            }}
          >
            <meta property="og:locale" content="cs_CZ" />
            <meta property="og:site_name" content="naZavody.cz" />
            <meta property="og:type" content="website" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#9f3ef5" />
            <meta name="msapplication-TileColor" content="#9f3ef5" />
            <meta name="theme-color" content="#9f3ef5" />
            <style data-react-helmet="true">
              {`
                :root {
                  --theme-primary: 159 62 245;
                  --theme-secondary: 88 9 158;
                  --theme-text-primary: 255 255 255;
                  --theme-text-secondary: 159 62 245;
                  --theme-dark-primary: 159 62 245;
                  --theme-dark-secondary: 88 9 158;
                  --theme-dark-text-primary: 255 255 255;
                  --theme-dark-text-secondary: 159 62 245;
                }`}
            </style>
          </Helmet>
          <Meta title="Na Závody" description="Registrace na závody a sportovní akce, kalendář závodů" image={images.ogImageFallback} />
          {background && <img className={backgroundClasses} src={backgroundImage} width={899} height={521} alt="pozadí" />}
          <header>
            {(alwaysShowOrganizerLinkBar || isLogged) && <OrganizerLinkTopBar isLogged={isLogged} />}
            <TopNavigation shadow={headerWithShadow} />
          </header>
          <main
            className={classNames(
              mainClasses,
              background && withBackgroundClasses,
              paddingOnMobile === "small" && paddingOnMobileSmallClasses,
              paddingOnMobile === "medium" && paddingOnMobileMediumClasses
            )}
          >
            {children}
          </main>
          <Footer />
        </div>
      </CookieConsentWrapper>
    </SSRProvider>
  )
}

import classNames from "classnames"

const containerClasses = classNames(`mx-auto max-w-7xl px-2 xs:px-4 md:px-8`)
const noPaddingOnMobileClasses = classNames(`px-0 md:px-8`)
const noPaddingOnXSMobileClasses = classNames(`px-0 xs:px-4 md:px-8`)

interface ContainerProps {
  children: any
  noPaddingOnMobile?: boolean
  noPaddingOnXSMobile?: boolean
}

export default function Container({ children, noPaddingOnMobile, noPaddingOnXSMobile }: ContainerProps) {
  return <div className={classNames(containerClasses, noPaddingOnMobile && noPaddingOnMobileClasses, noPaddingOnXSMobile && noPaddingOnXSMobileClasses)}>{children}</div>
}

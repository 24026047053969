interface StravaIconProps {
  classnames?: string
  color?: string
}

export default function StravaIcon({ classnames, color = "currentColor" }: StravaIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke={color} className={`flex-shrink-0 ${classnames}`}>
      <path d="M11.924,17.944 L9.835,13.828 L6.77,13.828 L11.924,24 L17.074,13.828 L14.008,13.828 M7,8.229 L9.836,13.827 L14.008,13.827 L7,0 L0,13.828 L4.169,13.828" transform="translate(3)" />
    </svg>
  )
}

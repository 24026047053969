import classNames from "classnames"

interface HamburgerProps {
  open: boolean
}

const hamburgerClasses = classNames(`relative m-0 inline-block h-5 w-5 rotate-0 p-0 transition`)
const lineClasses = classNames(`absolute left-0 block h-1 w-full rounded-sm bg-gray-900 transition ease-in-out dark:bg-white`)

export default function Hamburger({ open }: HamburgerProps) {
  const line1Classes = classNames({
    "top-0": !open,
    "top-2 rotate-135": open
  })

  const line2Classes = classNames({
    "top-2 opacity-100": !open,
    "top-2 translate-x-10 opacity-0": open
  })

  const line3Classes = classNames({
    "top-4": !open,
    "top-2 -rotate-135": open
  })

  return (
    <div className={hamburgerClasses}>
      <span className={classNames(lineClasses, line1Classes)} />
      <span className={classNames(lineClasses, line2Classes)} />
      <span className={classNames(lineClasses, line3Classes)} />
    </div>
  )
}

import CookieConsent from "@infrastructure/CookieConsent/CookieConsent"
import GaWrapper from "@infrastructure/ga/GaWrapper"
import { useState } from "react"

interface CookieConsentWrapperProps {
  children: React.ReactNode
}

export default function CookieConsentWrapper({ children }: CookieConsentWrapperProps) {
  const [cookieConsent, setCookieConsent] = useState(false)
  return (
    <>
      <GaWrapper cookieConsent={cookieConsent}>{children}</GaWrapper>
      <CookieConsent setCookieConsent={setCookieConsent} />
    </>
  )
}
